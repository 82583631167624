export function extensionColor(extension?: string) {
  switch (extension) {
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
      return "grey"
    case "pdf":
      return "red"
    case "xls":
    case "xlsx":
      return "green"
    case "doc":
    case "docx":
      return "blue"
    case "ppt":
    case "pptx":
      return "orange"
    case "zip":
    case "rar":
    case "7z":
    case "tar":
    case "gzip":
      return "purple"
    default:
      return "grey"
  }
}

export const sizeColor = (size: number) => {
  // Smaller then 1MB
  if (size < 1024000) return "success"

  // Between 1MB and 3MB
  if (size < 3000000) return "warning"

  // Bigger then 3MB
  return "error"
}
